import React from 'react'
import { ResponsiveObject } from 'react-slick'
import { Image } from '@/atoms/Image'
import { AsH1, TitleLG } from '@/atoms/Text'
import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

const defaultResponsiveSlick: ResponsiveObject[] = [
  {
    breakpoint: 1536,
    settings: {
      slidesToScroll: 6,
      slidesToShow: 6,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToScroll: 5,
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToScroll: 4,
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToScroll: 3,
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 640,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToScroll: 2,
      slidesToShow: 2,
    },
  },
]
export const GuildNowStreamingRail: React.FC = () => {
  const { nowStreamingProjects } = useGuildSignupContext()

  if (!nowStreamingProjects?.length) return null

  return (
    <div>
      <header>
        <TitleLG color="white" weight="bold" as={AsH1} className="mb-3 md:mb-4">
          Now Streaming
        </TitleLG>
      </header>

      <HorizontalItemScroller slidesToShow={6} slidesToScroll={6} responsive={defaultResponsiveSlick} arrows>
        {nowStreamingProjects?.map((item) => {
          if (!item) return null
          if (!item?.image?.cloudinaryPath) return null
          const title = item.project.name ?? undefined

          return (
            <div key={item.id}>
              <div className="mb-2 mr-4">
                <Image
                  aria-label={title}
                  alt={title}
                  src={item?.image?.cloudinaryPath as string}
                  height={450}
                  width={300}
                  className="rounded-lg bg-black"
                  unoptimized={false}
                />
              </div>
              <TitleAndSubtitleDisplay className="mr-4" title={title} />
            </div>
          )
        })}
      </HorizontalItemScroller>
    </div>
  )
}
