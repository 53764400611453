import React from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { AsH2, CaptionLG, HeadingLG, LabelLG, ParagraphSM } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export const GuildHowDoesItWorkV2: React.FC = () => {
  const { t } = useTranslate('join-the-guild')
  const { isDarkMode } = useThemeContext()
  return (
    <section
      className={classNames('px-6 py-[104px] lg:px-10', {
        'bg-core-gray-200': !isDarkMode,
        'bg-core-gray-950': isDarkMode,
      })}
    >
      <HeadingLG as={AsH2} weight="bold" color={isDarkMode ? 'white' : 'black'} className="mb-6 text-center">
        <Translate t={t} i18nKey="angelGuildMembersMakeOurShowsHappen">
          YOU are the key to changing the future of entertainment.
        </Translate>
      </HeadingLG>

      <div className="mb-6 py-3">
        <CaptionLG weight="semibold" color={isDarkMode ? 'white' : 'black'} className="text-center">
          <Translate i18nKey="everyMemberMakesADifference" t={t}>
            Every member helps us make more amazing shows, faster than ever.
          </Translate>
        </CaptionLG>
      </div>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <StepSection
          step={1}
          image={{
            alt: t('previewNewIdeas', 'Preview new ideas!'),
            src: '/v1723645399/angel-studios/guild/preview-new-ideas.webp',
          }}
          title={
            <span>
              <Translate i18nKey="previewNewIdeasForMoviesAndShows" t={t}>
                <b>Preview new ideas</b> for movies and shows seeking a home at Angel.
              </Translate>
            </span>
          }
        />
        <StepSection
          step={2}
          image={{
            alt: t('giveFeedback', 'Give feedback to filmmakers!'),
            src: '/v1723645399/angel-studios/guild/give-eedback.webp',
          }}
          title={
            <span>
              <Translate i18nKey="giveFeedbackToFilmmakers" t={t}>
                <b>Give feedback to filmmakers</b> to help them make the next big hit.
              </Translate>
            </span>
          }
        />
        <StepSection
          step={3}
          image={{
            alt: t('greenlightNewShows', 'Greenlight new shows!'),
            src: '/v1723645399/angel-studios/guild/greenlight.webp',
          }}
          title={
            <span>
              <Translate i18nKey="greenlightShowsYouLove" t={t}>
                <b>Greenlight shows you love</b> and bring them to the world!
              </Translate>
            </span>
          }
        />
      </div>
    </section>
  )
}

interface StepSectionProps {
  image: {
    alt: string
    src: string
  }
  title: React.ReactNode
  step: number
}

function StepSection({ image, title, step }: StepSectionProps) {
  const { isDarkMode } = useThemeContext()

  return (
    <div
      className={classNames('relative flex flex-col gap-6 rounded-2xl px-4 py-8', {
        'bg-core-gray-900': isDarkMode,
        'bg-white': !isDarkMode,
      })}
    >
      <Image className="mx-auto rounded-xl" width={600} height={400} alt={image.alt} src={image.src} />
      <div className="flex gap-4">
        <div
          className={classNames('flex h-8 w-8 shrink-0 items-center justify-center rounded-full', {
            'bg-info-300': step === 1,
            'bg-warning-300': step === 2,
            'bg-success-500': step === 3,
          })}
        >
          <LabelLG weight="bold" color="black">
            {step}
          </LabelLG>
        </div>
        <ParagraphSM weight="normal" color={isDarkMode ? 'white' : 'black'} className="md:photon-title-md">
          {title}
        </ParagraphSM>
      </div>
    </div>
  )
}
