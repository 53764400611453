import React from 'react'
import Marquee from 'react-fast-marquee'
import LazyHydrate from 'react-lazy-hydration'
import { Image } from '@/atoms/Image'
import { AsH2, AsH3, AsP, EyebrowMD, HeadingLG, HeadingXS, TitleSM } from '@/atoms/Text'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

export const GuildApprovals: React.FC = () => {
  const { guildApprovedTorchPosters } = useGuildSignupContext()
  const { t } = useTranslate('guild')
  return (
    <section className="relative mb-[104px] w-full overflow-x-hidden py-10" aria-labelledby="guild-approvals-title">
      <div className="pointer-events-none absolute inset-x-0 -top-[250px] bottom-0 z-0 bg-[url(https://images.angelstudios.com/image/upload/v1706728509/angel-studios/Show_Wall_Grid_-_Reveal_Top_Btm_3-1728x992.webp)] bg-cover opacity-10" />
      <div className="relative flex flex-col">
        <div className="grid grid-cols-12">
          <HeadingLG
            as={AsH2}
            id="guild-approvals-title"
            weight="bold"
            color="white"
            className="lg:photon-display col-span-10 col-start-2 col-end-11 mb-28 self-center lg:col-span-4 lg:col-start-2 lg:mb-4"
          >
            <Translate i18nKey="everyAngelReleaseHappensBecauseOfTheGuild" t={t}>
              Every Angel Studios release happens because of the Guild.
            </Translate>
          </HeadingLG>
          <Image
            className="pointer-events-none col-span-5 col-start-8 mb-[160px] hidden touch-none select-none lg:block"
            priority
            width={799}
            height={735}
            alt=""
            src={'/v1699544364/angel-studios/guild/Frame_1261157258.webp'}
          />

          <TitleSM
            as={AsH3}
            weight="bold"
            color="white"
            className="lg:photon-title-lg col-span-11 col-start-2 mb-4 self-center lg:col-span-4 lg:col-start-2"
          >
            <Translate i18nKey="recentlyApprovedGuild" t={t}>
              Recently approved by the Guild
            </Translate>
          </TitleSM>
          <LazyHydrate whenVisible>
            <Marquee loop={0} speed={50} className="col-span-12 mb-[160px]">
              {guildApprovedTorchPosters?.map((poster) => {
                return (
                  <img
                    key={poster}
                    src={getCloudinaryImageUrl({ path: `/${poster}.webp`, transforms: 'ar_16:9,c_fill', width: 300 })}
                    alt=""
                    className="pointer-events-none mr-6 touch-none select-none rounded-2xl"
                  />
                )
              })}
            </Marquee>
          </LazyHydrate>
          <div className="col-span-10 col-start-2 col-end-12 lg:col-span-4 lg:col-start-2">
            <Image
              className="pointer-events-none mx-auto mb-16 touch-none select-none self-end lg:m-0 lg:ml-auto lg:mr-16"
              priority
              alt=""
              src="/v1689696604/studio-app/catalog/f07fe000-df5b-4fcc-ad91-4cc2f61d2aa5.webp"
              height={273}
              width={183}
            />
          </div>
          <div className="col-span-10 col-start-2 col-end-12 self-center text-center lg:col-span-4 lg:col-start-6 lg:text-left">
            <EyebrowMD weight="bold" color="core-gray-500" className="mb-2">
              <Translate i18nKey="makingADifference" t={t}>
                Making a difference
              </Translate>
            </EyebrowMD>
            <HeadingXS as={AsP} color="white" weight="bold" className="lg:photon-heading-sm">
              <Translate i18nKey="over30mWatchedSofGuild" t={t}>
                Over 30 million people watched Sound of Freedom because Guild members made it happen when other studios
                wouldn&apos;t.
              </Translate>
            </HeadingXS>
          </div>
        </div>
      </div>
    </section>
  )
}
