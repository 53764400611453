import { FC } from 'react'
import classNames from 'classnames'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { AsH2, HeadingSM, ParagraphLG } from '@/atoms/Text'
import { AppDownloadLinks } from '@/organisms/AppDownloadLinks'
import { useTranslate } from '@/utils/translate/translate-client'

interface Props {
  overrideAppImageUrl?: string
  containerClassName?: string
  className?: string
  simplified?: boolean
}

export const AppPromoSection: FC<Props> = ({
  overrideAppImageUrl,
  containerClassName,
  className,
  simplified = false,
}): JSX.Element => {
  const { t } = useTranslate('app-promo')

  return (
    <section
      role="complementary"
      className={classNames('relative w-full bg-core-gray-950 pb-12 md:pb-24 md:pt-32', containerClassName)}
      id="download"
    >
      <PaddedContainer>
        <div
          className={classNames(
            'relative flex flex-col items-center justify-between gap-6 pb-32 lg:flex-row lg:gap-20',
            className,
          )}
        >
          <div className="h-full w-full lg:min-w-[500px] xl:min-w-[550px] 2xl:min-w-[650px]">
            <Image
              src={overrideAppImageUrl ?? '/b_rgb:141414/v1715792164/angel-studios/landing/watch_amytime_-_image.png'}
              width={1684}
              height={1141}
              layout="responsive"
              alt={t('angelStudiosOnDevices', 'Angel Studios on multiple devices')}
            />
          </div>
          <div className="h-full w-full">
            <div className="mb-6">
              <HeadingSM
                as={AsH2}
                color="core-gray-100"
                className={classNames('mb-4 text-center lg:text-left', { 'pb-4': simplified })}
                weight="bold"
              >
                {t('watchAnytimeAnywhere', 'Watch Anytime. Anywhere.')}
              </HeadingSM>
              {!simplified && (
                <ParagraphLG color="core-gray-300" className="text-center lg:text-left">
                  {t(
                    'theAngelAppDescriptionv2',
                    'The Angel app is the home of record-shattering stories that amplify light. In the Angel app users can watch full episodes, cast to their television, Pay it Forward to fund future seasons of shows they love and buy official merchandise. Download the app below for free.',
                  )}
                </ParagraphLG>
              )}
            </div>
            <div
              data-testid={t('angelStudiosAppDownloads', 'Angel Studios App Downloads')}
              className="mt-6 flex items-center justify-center lg:justify-start"
            >
              <AppDownloadLinks darkMode />
            </div>
          </div>
        </div>
      </PaddedContainer>
    </section>
  )
}
